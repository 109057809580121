:root {
  --node-padding: 12px; /* also used by zap nodes */
}

/** This is to show the + step button */
.react-flow__node:hover [class*="AddStepButton"] svg {
  visibility: visible;
}

.react-flow__node-default:hover, .react-flow__node-zap:hover {
  z-index: 1000 !important; /* For add step menu to be on top of other nodes next to it (matches selected node's z-index) */
}

.react-flow__node-default.selectable:has(#asset-node):focus,
.react-flow__node-default.selectable:has(#asset-node):focus-visible {
  box-shadow: 0 0 0 0.5px var(--zds-ui-brand);
}

/** ZAP NODES */
.react-flow__node-zap {
  background: transparent;
}
