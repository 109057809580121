/* Fixes bug going from Canvas list page to individual Canvas page*/
.jotai-devtools-trigger-button {
  position: fixed !important;
  border-radius: 50% !important;
  border-width: 0 !important;
  width: 4rem !important;
  height: 4rem !important;
  z-index: 1; /* Makes the button appear behind modal overlays */
}

/* Custom Remirror styling */
.MuiTooltip-tooltip {
  background-color: var(--zds-colors-neutral-800) !important;
}

/* For this not to show white borders https://cdn.zappy.app/10b102abf368ca275797c34dcb91ec22.png */
.MuiPopper-root > .MuiStack-root {
  border-radius: 5px;
}

/* Makes the emoji ellipsis not white and more visible */
.remirror-emoji-popup-item {
  color: #798186 !important;
}
